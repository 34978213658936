import getPkce from "oauth-pkce";

export default class AuthHandler {

    authorize() {
        this.authWindow = window.open("", "_blank");
        getPkce(43, (error, { verifier, challenge }) => {
            if (!error) {
                const createdAt = new Date();
                const codePair = {
                    codeVerifier: verifier,
                    codeChallenge: challenge,
                    createdAt
                  }
                  window.localStorage.setItem('pkce', JSON.stringify(codePair))
        window.localStorage.setItem('preAuthUri', window.location.href)
        window.localStorage.removeItem('auth')
        this.codePair = codePair;
    
        const query = {
          scope: "all",
          client_id: "ss",
          response_type: 'code',
          redirect_uri: "",
          code_challenge: codePair.codeChallenge,
          code_challenge_method: 'S256'
        }

        var queryString = Object.keys(query).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
        }).join('&');
    
        const url = process.env.REACT_APP_YSNDIT_ENDPOINT + "OAuth2/auth?" + queryString;
        this.authWindow.document.location.href = url;

        const timer = setInterval(() => {
            if (this.authWindow.closed) {
              clearInterval(timer);
              var formdata = new FormData();
        formdata.append("grant_type", "authorization_code");
        formdata.append("code_verifier", this.codePair.codeVerifier);
        formdata.append("code", this.codePair.codeChallenge);
        
        var myHeaders = new Headers();
        if (process.env.NODE_ENV == 'development') {
          myHeaders.append("CF-Access-Client-Id", process.env.REACT_APP_CF_ID);
          myHeaders.append("CF-Access-Client-Secret", process.env.REACT_APP_CF_SECRET);
        }
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow'
        };
        fetch(process.env.REACT_APP_YSNDIT_ENDPOINT + "OAuth2/token", requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data["status"] == 200) {
                    window.localStorage.setItem('token', data["token"])
                    const event = new Event('tokenUpdated');
                    window.document.dispatchEvent(event);
                }
                
              
            });
             
              this.authWindow = null;
            }
          }, 500);
            }
          });
    }
}