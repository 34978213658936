import {createStyles, Container, Group, Anchor, rem, Image} from '@mantine/core';
import {useContext} from "react";
import {websiteContext} from "../data/websiteContext";

const useStyles = createStyles((theme) => ({
    footer: {
        marginTop: rem(120)
    },

    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,

        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column',
        },
    },

    links: {
        [theme.fn.smallerThan('xs')]: {
            marginTop: theme.spacing.md,
        },
    },
}));

export function Footer({ links }) {
    const websiteData = useContext(websiteContext);
    const { classes } = useStyles();
    const items = links.map((link) => (
        <Anchor
    key={link.label}
    href={link.link}
    size="sm"
        >
        {link.label}
</Anchor>
));

    return (
        <div className={classes.footer}>
            <Container className={classes.inner}>
                <Image src={(websiteData.siteData != null) ? websiteData.siteData.logo : null} height={28} width={28} />
                <Group className={classes.links}>{items}</Group>
            </Container>
        </div>
    );
}