
import {createStyles, Paper, Text, Title, Button, rem, Image, Stack} from '@mantine/core';

import image from '../../assets/img/gradient-bg.jpeg';
import {IconArrowRight} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
	card: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},

	image: {
		marginBottom: theme.spacing.xl
	},

	title: {
		fontFamily: `Greycliff CF ${theme.fontFamily}`,
		fontWeight: 900,
		color: theme.white,
		lineHeight: 1.2,
		fontSize: rem(32),
	},

	titleNoBackground: {
		fontFamily: `Greycliff CF ${theme.fontFamily}`,
		fontWeight: 900,
		color: theme.dark,
		lineHeight: 1.2,
		fontSize: rem(32),
	},

	description: {
		color: theme.white,
		opacity: 0.7,
		fontWeight: 700,
		marginTop: theme.spacing.sm,
	},

	descriptionNoBackground: {
		color: theme.dark,
		opacity: 0.7,
		fontWeight: 600,
		marginTop: theme.spacing.sm,
	},
	cta: {
		marginTop: theme.spacing.xl,
	},
}));

export function Future({ title, description, cta, ctaLink, img, noBackground=false, backgroundStyle = {} }) {
	const { classes } = useStyles();

	return (
		<Paper
			shadow="md"
			p="xl"
			radius="md"
			sx={(noBackground) ? {} : { ...backgroundStyle, backgroundImage: `url(${image})` }}
			className={classes.card}
		>
			<div>
				{img != null &&
				<Image className={classes.image} width="100px" fit="contain" src={img} />
				}
				<Title order={3} variant="gradient"
					   gradient={{ from: (noBackground) ? 'indigo' : 'white', to: (!noBackground) ? '#dad9ff' : 'cyan', deg: 45 }} className={(noBackground) ? classes.titleNoBackground : classes.title}>
					{title}
				</Title>
				<Text className={(noBackground) ? classes.descriptionNoBackground : classes.description} size="lg">
					{description}
				</Text>
			</div>
			{cta != null &&
			<Button rightIcon={<IconArrowRight />} component="a" href={ctaLink} variant={noBackground ? "outline" : "white"} className={classes.cta}>
				{cta}
			</Button>
			}
		</Paper>);
}