import React from 'react';
import { Link, Text, Switch, View } from '@adobe/react-spectrum';
import { Accordion, createStyles, List, ThemeIcon, Box, Flex, Alert, Paper } from '@mantine/core';
import { IconCookie, IconFingerprint, IconId, IconIdOff, IconShieldShare } from '@tabler/icons-react';

import './ConsentSettings.css';


const ConsentSettings = ({consentHandler, consentOptions, setConsentOptions}) => {

    const useStyles = createStyles((theme) => ({
      root: {
        borderRadius: theme.radius.sm,        
      },
    
      item: {
        backgroundColor: theme.colorScheme === 'dark' ? "transparent" : theme.colors.gray[0],
        border: '${rem(1)} solid transparent',
        position: 'relative',
        zIndex: 0,
        transition: 'transform 150ms ease',
        
        '&[data-active]': {
          transform: 'scale(1.03)',
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.blue[0],
          borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
          borderRadius: theme.radius.md,
          zIndex: 1,
          borderBottom: "0px",
        },

        '&:hover': {
          transform: 'scale(1.03)',
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.blue[0],
          borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
          borderRadius: theme.radius.md,
          borderBottom: "0px",
          zIndex: 1,
        },
      },

      control: {
        '&:hover': {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.blue[0],
          borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
          borderRadius: theme.radius.md,
          zIndex: 1,
        },
      },

      panel: {
        paddingRight: "10px"
      }
      
    }));

    const listStyle = createStyles((theme) => ({
      root: {
        color: "#FFFFFF",
      },
    }));

    const { classes } = useStyles();
    const { listclass } = listStyle();

   return (
      <>
      <View marginBottom="15px">
      <Text>
       By clicking "Reject all" or "Save", you agree to our <Link><a href={consentHandler.consent.consentData.termsUrl} target="_blank">terms</a></Link>, <Link><a href={consentHandler.consent.consentData.privacyUrl} target="_blank">privacy policy</a></Link> and the cookies you select below. You can adjust or revoke your consent anytime on the <Link><a href={consentHandler.consent.consentData.privacyUrl} target="_blank">privacy page</a></Link>.
</Text>
      </View>
       
<Accordion
      mx="auto"
      variant="default"
      defaultValue="optional"
      chevronPosition="left"
      classNames={classes}
      className={classes.root}
>
      <Accordion.Item value="technical">
      <Box sx={{ display: 'flex', alignItems: 'center'}}>
      <Accordion.Control>Technical required data</Accordion.Control>
      <Switch isDisabled isEmphasized isSelected UNSAFE_style={{marginRight: "12px"}} />
    </Box>
        <Accordion.Panel>
        <List
      spacing="xs"
      size="sm"
      icon={
        <ThemeIcon variant="default" size="sm">
          <IconCookie size="1rem" />
        </ThemeIcon>
      }
    >
      { !consentHandler.requiredPermissions.hiddenOptions.includes("browseridCookie") &&
      <List.Item>Unique Browser ID Cookie. This ID is only used to enable you to view your previous transfers</List.Item>
      }
      { !consentHandler.requiredPermissions.hiddenOptions.includes("sessionCookie") &&
      <List.Item>Session Cookie. Used exclusively to identify you when you log in and to store your language</List.Item>
      }
      { !consentHandler.requiredPermissions.hiddenOptions.includes("fingerprint") &&
      <List.Item
      icon={
        <ThemeIcon variant="default" size="sm">
          <IconFingerprint size="1rem" />
        </ThemeIcon>
      }
      >Browser Fingerprint. Only used to count how many uploads were started from this browser, identify new uploaders and to take actions accordingly</List.Item>
      }
      <List.Item
      icon={
        <ThemeIcon variant="default" size="sm">
          <IconId size="1rem" />
        </ThemeIcon>
      }
      >Information about your person
      
      <List
      size="xs" listStyleType="disc">
        <List.Item>IP address
        <br />
        <i>By loading external scripts, the ip address is also passed to servers from {(consentHandler.requiredPermissions.ipSharedWith3rdParty.join(", "))}</i>
    </List.Item>
          <List.Item>Email, Username</List.Item>
          <List.Item>General information (e.g. language you use, time of your visits to the website or app)</List.Item>
          <List.Item>Uploaded files</List.Item>
        </List>
      
      </List.Item>
     
    </List>

        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="improvements">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Accordion.Control>Data used for improvements and monetization</Accordion.Control>
      <Switch isDisabled isEmphasized isSelected UNSAFE_style={{marginRight: "12px"}} />
    </Box>
        <Accordion.Panel>

        <List
      spacing="xs"
      size="sm"
      center={false}
    >
      <List.Item
      icon={
        <ThemeIcon variant="default" size="sm">
          <IconIdOff size="1rem" />
        </ThemeIcon>
      }
      >We use PostHog to analyze website and app usage. The data obtained from this is used to optimize our service for you. The following data, among other things, is recorded during your website visit or app use: Pages accessed, achieving "website goals" (e.g. uploading your first file), your behavior on the pages (e.g. clicks, scrolling behavior and length of stay), your approximate location (country and city), your IP address (in abbreviated form so that no clear assignment is possible), technical information such as browser, internet provider, device and screen resolution, source of origin of your visit (i.e. via which website or which advertising material you came to us), this data is transmitted to a PostHog server in the USA. PostHog observes the data protection provisions of the "EU-US Privacy Shield" agreement</List.Item>
      { !consentHandler.requiredPermissions.hiddenOptions.includes("adsCookie") &&
      <List.Item
      icon={
        <ThemeIcon variant="default" size="sm">
          <IconCookie size="1rem" />
        </ThemeIcon>
      }
      >Use of cookies and other forms of local storage to display advertisements. You can find a list of all advertising partners and their privacy statements <Link><a href={consentHandler.consent.consentData.adsUrl} target="_blank">here</a></Link></List.Item>
    }
    </List>

        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="optional">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Accordion.Control>Optional data you may refuse to collect</Accordion.Control>
      <Switch isEmphasized isSelected={((consentOptions.analyticsCookie && !consentHandler.requiredPermissions.hiddenOptions.includes("analyticsCookie")) || (consentOptions.adsPersonalized && !consentHandler.requiredPermissions.hiddenOptions.includes("adsPersonalized")))} onChange={(value) => {setConsentOptions({...consentOptions, ["adsPersonalized"]: (!consentHandler.requiredPermissions.hiddenOptions.includes("adsPersonalized")) ? value : false, ["analyticsCookie"]: (!consentHandler.requiredPermissions.hiddenOptions.includes("analyticsCookie")) ? value : false})}}  />
    </Box>
        <Accordion.Panel sx={{padding:'0px !important'}} styles={{content:{padding: "0px", backgroundColor:"#000000"}}}>

        <List
      spacing="xs"
      size="sm"
      center={false}
      sx={{padding:'0px'}}
    >
      
      <List.Item
      icon={
        <ThemeIcon variant="default" size="sm">
          <IconCookie size="1rem" />
        </ThemeIcon>
      }
      ><Switch isSelected={consentOptions.analyticsCookie} onChange={(value) => {setConsentOptions({...consentOptions, ["analyticsCookie"]: value})}} UNSAFE_style={{flexDirection:'row-reverse'}} UNSAFE_className="toggleOption" margin={0} isEmphasized><p style={{fontSize: "14px", width: "100%", margin: "0px"}}>PostHog Analytics stores cookies in your web browser or ysendit app for a period of one year since your last visit. These cookies contain a randomly generated user ID with which you can be recognized on future website visits or app uses</p></Switch></List.Item>
      
      { !consentHandler.requiredPermissions.hiddenOptions.includes("adsPersonalized") &&
      <List.Item
      icon={
        <ThemeIcon variant="default" size="sm">
          <IconId size="1rem" />
        </ThemeIcon>
      }
      
      ><Switch isSelected={consentOptions.adsPersonalized} onChange={(value) => {setConsentOptions({...consentOptions, ["adsPersonalized"]: value})}} UNSAFE_style={{flexDirection:'row-reverse'}} UNSAFE_className="toggleOption" margin={0} isEmphasized><p style={{fontSize: "14px", width: "100%", margin: "0px"}}>Collection, disclosure and use of personal data to personalize advertisements</p></Switch></List.Item>
    }
     
      
    </List>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>

    <Flex
      gap="xs"
      mt="20px"
      mb="20px"
      justify="center"
      align="center"
      direction="row"
      wrap="wrap"
    >
       <Text UNSAFE_style={{fontSize: "13px"}}><ThemeIcon variant="default" size="xs">
          <IconCookie size="0.7rem" />
        </ThemeIcon> uses cookies</Text>
        
        <Text UNSAFE_style={{fontSize: "13px"}}><ThemeIcon variant="default" size="xs">
          <IconId size="0.7rem" />
        </ThemeIcon> associated with you</Text>
        
        <Text UNSAFE_style={{fontSize: "13px"}}><ThemeIcon variant="default" size="xs">
          <IconIdOff size="0.7rem" />
        </ThemeIcon> anonimized data</Text>
    </Flex>

    <Text>Unless explicitly stated otherwise, your data will only be processed by {consentHandler.consent.consentData.siteName}</Text>
        
      </>
   )
}

export default ConsentSettings;