import {
	Container,
	Grid,
	SimpleGrid,
	Skeleton,
	useMantineTheme,
	rem,
	Paper,
	Group,
	Card,
	Text,
	Menu,
	ActionIcon,
	Switch, Collapse, Image, Flex, Title, Button, Stack, Center, Anchor, createStyles, Modal, Space
} from '@mantine/core';
import {Uploader} from "../Uploader/Uploader";
import { WebsiteHeader} from "./WebsiteHeader";
import {
	IconArrowRight, IconCirclePlus,
	IconDots,
	IconEye,
	IconFileZip,
	IconHeartHandshake,
	IconHelp,
	IconLink,
	IconMail,
	IconTrash
} from "@tabler/icons-react";
import {TransfersList} from "../Account/TransfersList";
import {useContext, useEffect, useState} from "react";
import {websiteContext} from "../data/websiteContext";

import commentingImage from "../../assets/img/comments.png"
import chatBubbles from "../../assets/img/ChatBubbles.png"
import background from '../../assets/img/gradient-bg.jpeg';
import {Future} from "./Future";
import image from "../../assets/img/gradient-bg.jpeg";
import noAd from "../../assets/img/noAd.png";
import previews from "../../assets/img/previews.png";
import ownDomain from "../../assets/img/ownDomain.png";
import donate from "../../assets/img/donate.png";
import brand from "../../assets/img/brand.png";
import instance from "../../assets/img/instance.png";
import {Future2} from "./Future2";
import {ButtonGroup, Content, Dialog, DialogContainer, Divider, Heading, useProvider} from "@adobe/react-spectrum";
import {MasonryGrid} from "./Masonry/Masonry";
import AuthHandler from "../data/AuthHandler";
import BigAvatar from "./BigAvatar/BigAvatar";
import {Footer} from "./Footer";
import { useMediaQuery } from '@mantine/hooks';

const PRIMARY_COL_HEIGHT = rem(300);

const useStyles = createStyles((theme) => ({
	root: {
		marginBottom: 100,

		[theme.fn.smallerThan('xs')]: {
			marginBottom: 40
		},
	}
}));


export function HomePage() {
	const theme = useMantineTheme();
	const SECONDARY_COL_HEIGHT = `calc(${PRIMARY_COL_HEIGHT} / 2 - ${theme.spacing.md} / 2)`;

	const { classes, cx } = useStyles();


	const websiteData = useContext(websiteContext);

	const [shareType, setShareType] = useState("link");

	const [hasLoaded, setIsLoaded] = useState(false);

	const [avatarLoaded, setAvatarLoaded] = useState(true);

	const [helpSendOpen, setHelpSendOpen] = useState(false);

	const urlParams = new URLSearchParams(window.location.search);
	const premiumSuccess = urlParams.has('premiumSuccess');

	const [upgradeOpen, setUpgradeOpen] = useState(premiumSuccess)
	const isMobile = useMediaQuery("(max-width: 50em)");

	const isYsenditCom = window.location.hostname === "ysendit.com";

	const [links, setLinks] = useState(getLinks);

	function handleUpload(ev) {
		if(ev.data && ev.data.uploadCompleted && (websiteData.user == null || !websiteData.user.adFree)) {
			//setUpgradeOpen(true);
		}
	}

	function getLinks() {
		const links = []
		if(websiteData.siteData && websiteData.siteData.helpUrl && websiteData.siteData.helpUrl != "") {
			links.push({link: websiteData.siteData.helpUrl, label: "Learn more"})
		}

		if(isYsenditCom) {
			links.push({link: "https://help.ysendit.com/updates-and-upcoming/changelog", label: "New features"})
		}

		if(websiteData.siteData && websiteData.siteData.premium_purchasable) {
			links.push({link: (websiteData.user == null || (websiteData.user != null && !websiteData.user.adFree)) ? "/pricing" : "#", label: (websiteData.user == null || (websiteData.user != null && !websiteData.user.adFree)) ? "Upgrade" : "You are a Pro!", upgrade: websiteData.user && websiteData.user.adFree})
		}

		if(isYsenditCom) {
			links.push({link: "https://ysendit.com/hosted/", label: "Get your own file sharing site"})
		}

		return links;
	}

	useEffect(() => {
        window.addEventListener('message', handleUpload)

        // Don't forget to remove addEventListener
        return () => window.removeEventListener('message', handleUpload)
    }, [websiteData.user])

	useEffect(() => {
		setLinks(getLinks);
	}, [websiteData.user, websiteData.siteData])



	return (
		<>

<Modal styles={(theme) => ({
          body: {
            backgroundColor: '#cce6ff',
          },
		  content: {
            backgroundColor: '#cce6ff',
			borderRadius: '6px'
          },
		  header: {
            backgroundColor: '#cce6ff',
          },
        })} fullScreen={isMobile} padding={(isMobile) ? 50 : 30} opened={upgradeOpen} onClose={() => setUpgradeOpen(false)} centered>
    
			<Stack spacing="lg">
				<Center>
				<IconHeartHandshake size={100} color='#1f68dd' />
				</Center>
			<div>
			<Title color='dark' order={2}>You are a Pro!</Title>
			<Space />
				{(isYsenditCom) && <Text color='dark'>A big thank you for your support!
					With Premium, no ads will be placed on your transfers and you can serve them under your own domain. To add a custom domain name, please reach out to us.</Text>}

				{!isYsenditCom &&
					<Text color='dark'>A big thank you for your support!
						With Premium, no ads will be placed on your transfers uploaded from your account.</Text>}

			</div>

			<Button color='dark' onClick={() => {window.open("/billing/getPortalUrl")}} variant="light">
      Update your Plan
    </Button>
			
			</Stack>
	
      </Modal>


		<Container>
			<WebsiteHeader openUpgrade={setUpgradeOpen} links={links} active="/pricing" />

			<Center className={classes.root}>
				<Flex
					gap="md"
					justify="center"
					align="center"
					direction="row"
					wrap="wrap"
				>


					<BigAvatar src={(websiteData.user != null && websiteData.user.avatar != null) ? process.env.REACT_APP_YSNDIT_ENDPOINT + "assets/img/custom/avatars/" + websiteData.user.avatar : null} />
					<Stack spacing="xs">
						<Title order={1}>Your toolbox for sharing files.</Title>

						{(websiteData.user != null && websiteData.user.authenticated) &&
							<Text fw={500} fz="xl">Welcome back, {websiteData.user.name}! <Anchor href={process.env.REACT_APP_YSNDIT_ENDPOINT + "profile?redirect_to=" + encodeURI(window.location.href)}>Update profile</Anchor> or <Anchor href={process.env.REACT_APP_YSNDIT_ENDPOINT + "user/logout?redirect=" + encodeURI(window.location.href)}>
								logout
							</Anchor></Text>
						}

						{(websiteData.user == null || !websiteData.user.authenticated) &&
							<Text fw={500} fz="xl">Happy to meet you! Lets make it official, <Anchor onClick={() => {const handler = new AuthHandler(); handler.authorize();}}>
								Sign up or Log in
							</Anchor>.</Text>
						}
					</Stack>



				</Flex>




			</Center>

			<Grid cols={2} spacing="md"  mb={30}>

				<Grid.Col style={{width:"300px"}} sm="content" span={12} >
					{(websiteData.consent != null && websiteData.user != null && websiteData.user.mobile) &&
					<Card withBorder shadow="sm" w="100%" radius="md" style={{overflow: "hidden"}}>
						<Card.Section withBorder inheritPadding py="xs">
							<Group position="apart">
								<Group spacing="xs">
									<Text weight={500}>Send files</Text>
								</Group>
							</Group>
						</Card.Section>
						<Card.Section withBorder inheritPadding py="xs">
							<Stack>
								<Text>Select files you want to share. After submitting, you' ll get a link that you can share with your friends, family, colleagues, ... They' ll see a preview of your files in the browser and can download them.</Text>
								<Button variant="filled" component="a" href={process.env.REACT_APP_YSNDIT_ENDPOINT + "upload.page?dark_mode="+ (theme.colorScheme == 'dark') +"&consent=" + JSON.stringify(websiteData.consent)} leftIcon={<IconCirclePlus />}>Select files to send</Button>
							</Stack>
						</Card.Section>
					</Card>
					}
							{(websiteData.consent != null && websiteData.user != null && !websiteData.user.mobile) &&
								<Card withBorder shadow="sm" radius="md" w="280px" style={{overflow: "hidden", display: (hasLoaded) ? "block":"none"}}>

									{(!websiteData.user.mobile) &&
									<Card.Section withBorder inheritPadding py="xs">
										<Group position="apart">
											<Group spacing="xs">
												<Text weight={500}>Send files</Text>
												<ActionIcon onClick={() => {setHelpSendOpen(!helpSendOpen)}} size="xs" color="blue" variant={(helpSendOpen) ? "filled" : "default"}>
													<IconHelp size="0.9rem" />
												</ActionIcon>
											</Group>


											<Switch
												size="md"
												color={theme.colorScheme === 'dark' ? 'gray' : 'accent'}
												onLabel={<IconLink size="1rem" stroke={2.5} color="white" />}
												offLabel={<IconMail size="1rem" stroke={2.5} color={theme.colors.blue[6]} />}
												withinPortal position="bottom-end" shadow="sm"
												checked={shareType == "mail"} onChange={(event) => setShareType(event.currentTarget.checked ? "mail" : "link")}
											/>
										</Group>
									</Card.Section>
									}

									{helpSendOpen &&
									<Collapse in={helpSendOpen}>
										<Text mt="sm" mb="sm" color="dimmed" size="sm">
											Choose the files you want to share. After submitting, you' ll get a link that you can share with your friends, family, colleagues, ... They' ll see a preview of your files in the browser and can download them
										</Text>

									</Collapse>
									}


									<Card.Section>

										<Uploader browserId={websiteData.user.browserId} setIsLoaded={setIsLoaded} consent={JSON.stringify(websiteData.consent)} borderRadius="0px" blank noBorder shareType={shareType} darkMode={theme.colorScheme === 'dark'}/>


									</Card.Section>
								</Card>
							}
							{(websiteData.consent == null || websiteData.user == null || (!hasLoaded && !websiteData.user.mobile)) &&
								<Skeleton w="100%" height={SECONDARY_COL_HEIGHT} radius="md" animate={true} />
							}




				</Grid.Col>
				<Grid.Col sm="auto" span={12} >


							{(websiteData.consent != null && websiteData.user != null) &&
								<Card withBorder shadow="sm" radius="md">
									<Card.Section withBorder inheritPadding py="xs">
										<Group position="apart">
											<Text weight={500}>Made transfers</Text>
										</Group>
									</Card.Section>
									<Card.Section withBorder inheritPadding py="xs">
										<TransfersList user={websiteData.user} />
									</Card.Section>
									
									{(!websiteData.user.authenticated) &&
									<div>
									<Grid grow justify="space-between" align="center">
      <Grid.Col span={6}>
	  <Text weight={500} size="lg" mt="md">
        You are not logged in. Made transfers may disappear.
      </Text>
	  </Grid.Col>
      <Grid.Col span="auto">
	  <Button onClick={() => {const handler = new AuthHandler(); handler.authorize();}} variant="default" mt="md" w="100%" color="blue" radius="md">
        Sign up
      </Button>
	  </Grid.Col>
    </Grid>
	<Text mt="xs" color="dimmed" size="sm">
	  With a free account you can list all transfers you have started in the past and delete them before their expiry date.
      </Text>
	  </div>
}

									{(websiteData.user.authenticated && !websiteData.user.adFree && websiteData.siteData.premium_purchasable) &&
										<div>
											<Grid grow justify="space-between" align="center">
												<Grid.Col span={6}>
													<Text weight={500} size="lg" mt="md">
														Don't want to bother your downloaders with ads?
													</Text>
												</Grid.Col>
												<Grid.Col span="auto">
													<Button onClick={() => {window.open("/pricing");}} variant="default" mt="md" w="100%" color="blue" radius="md">
														Upgrade to Premium
													</Button>
												</Grid.Col>
											</Grid>
											<Text mt="xs" color="dimmed" size="sm">
												Disable ads on your transfers and benefit from other exclusive premium features.
											</Text>
										</div>
									}

      
								</Card>
							}
							{(websiteData.consent == null || websiteData.user == null) &&
								<Skeleton height={SECONDARY_COL_HEIGHT} radius="md" animate={true} />
							}

				</Grid.Col>
			</Grid>

			{(isYsenditCom) &&
				<>
				<Space h="xl" />
			<MasonryGrid>
				<Future title="Introducing annotations." description="Comment and annotate files directly on the download page. Whether you want to provide feedback, ask questions, or offer suggestions, it's all just a click away." cta="Try now" ctaLink="https://ysendit.com/GT6NcoeD" img={chatBubbles} backgroundStyle={ {backgroundSize: "1200px", backgroundPosition:"center"}} />
				{(websiteData.user != null && !websiteData.user.adFree && websiteData.siteData.premium_purchasable) &&
				<Future title="Hide ads?" description="Disable ads on the download page for everyone who downloads your files." cta="Upgrade now" ctaLink="/pricing" backgroundStyle={{backgroundSize: "1200px", backgroundPosition: "right"}}/>
				}
				<Future title="Preview files" description="50+ file types including .docx, .pptx and .epub can be previewed directly on the download page." cta="Learn more" ctaLink="https://help.ysendit.com/preview-files" img={previews} noBackground />
				<Future title="Fully free" description="ysendit is a fully free file sharing service powered by you and others that love to send files. We believe in making file sending as easy and as comfortable as possible. That‘s why we put file sending in focus and don‘t ask you to subscribe to a monthly plan." cta="Contribute" ctaLink="https://help.ysendit.com/i-need-your-help" img={donate} backgroundStyle={{backgroundSize: "1200px", backgroundPosition: "top"}}/>
				<Future title="Keep your transfers" description="You will see a list of all the transfers you have done in the past with this browser at the top. If you sign up, your transfers will even be shared across all your devices" cta="Learn more" ctaLink="https://help.ysendit.com/keep-your-transfers" noBackground/>
				<Future title="Brand" description="Your Transfers belong to you. Customize every aspect of your download pages. Upload your custom logo and link it to your own website. Select an image as background for the download page or put your website in it." img={brand} backgroundStyle={{backgroundSize: "1200px", backgroundPosition: "left"}}/>
				{(websiteData.user != null && !websiteData.user.adFree && isYsenditCom && websiteData.siteData.premium_purchasable) &&
					<Future title="Custom domain" description="Let your files download from your own domain or a free .ysendit.com subdomain." cta="Upgrade now" ctaLink="/pricing" img={ownDomain} noBackground/>
				}
				<Future title="Want to build your own file sharing site?" description="Then ysendit has the right infrastructure for you as we operate our own file sharing service. We rent it out as a white labeling product tailored to your needs. Read more about features and try it." cta="Learn more" ctaLink="https://ysendit.com/hosted" noBackground/>
				<Future title="Creator" description="I'm Niklas Weber, a computer science student based in Karlsruhe. ysendit is my first larger project I have developed over a longer period of time." noBackground/>
			</MasonryGrid>
				</>
			}
			<Footer links={[{link: "https://nwebr.atlassian.net/servicedesk/customer/portal/5", label: "Contact"}, {link: "https://ysendit.statuspage.io", label: "System status"}, {link: (websiteData.siteData != null) ? websiteData.siteData.privacyUrl : "/privacy", label: "Privacy"}, {link: (websiteData.siteData != null) ? websiteData.siteData.termsUrl : "/terms", label: "Terms"}, {link: (websiteData.siteData != null) ? websiteData.siteData.imprintUrl : "/imprint", label: "Imprint"}]} />
		</Container>
		</>
	);
}