import { MantineProvider } from '@mantine/core';
import './App.css';


import { useProvider } from '@adobe/react-spectrum';
import {useEffect, useState} from 'react';
import {HomePage} from "./Components/Website/HomePage";

import {getUser, websiteContext} from "./Components/data/websiteContext";

import User from "./User";
import {ConsentHandler} from "./Components/ConsentModal/components/Consent";
import ConsentModal from "./Components/ConsentModal/components/ConsentModal";
import {initSiteData} from "./Components/data/websiteContext";
import posthog from "posthog-js";

function App() {
  let { colorScheme } = useProvider();

  const [siteData, setSiteData] = useState({consent: null, user: null, siteData: null});

  function consentGiven(consent) {
      getUser().then((user) => {
          setSiteData({consent: consent, ...user})
      })
    if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
      posthog.init('phc_6Ft66C6ijHSio8nVqv6PdsiBVLekIbICerNu9umtrGE',{api_host:'https://app.posthog.com', opt_in_site_apps: true, disable_persistence: !(consent["consentOptions"]["analyticsCookie"])});
    }
    ConsentHandler.appendScript("https://ysendit.statuspage.io/embed/script.js", {}, "body");
  }

  function setUser() {
    getUser().then((user) => {
      setSiteData({ consent: siteData.consent, ...user })
    })
  }

  useEffect(() => {

    window.document.addEventListener("tokenUpdated", setUser);
    return () => {
      // unsubscribe event
      window.document.removeEventListener("tokenUpdated", setUser);
    };
  }, [siteData])

  return (
      <MantineProvider theme={{ colorScheme: ((colorScheme == 'dark') ? 'dark' : 'light'),
  colors: {
    blue: [
      "#E5F2FF",
      "#B8DBFF",
      "#8AC4FF",
      "#5CADFF",
      "#2E96FF",
      "#007FFF",
      "#0066CC",
      "#004C99",
      "#003366",
      "#001933"
    ],
    dark: [
      "#ebebeb",
      "#C1C2C5",
      "#A6A7AB",
      "#909296",
      "#5C5F66",
      "#373A40",
      "#2C2E33",
      "#25262B",
      "#1A1B1E",
      "#141517"
    ]
  }, primaryColor: 'blue', globalStyles: (theme) => ({


          body: {
            background: (theme.colorScheme === 'dark') ? "linear-gradient(135deg, rgba(48,50,99,1) 0%, rgba(22,22,22,1) 50%, rgba(19,60,103,1) 100%)" : "linear-gradient(135deg, rgba(238,242,255,1) 0%, rgba(255,255,255,1) 50%, rgba(207,250,254,1) 100%)",
          },
        })}} >
        <websiteContext.Provider value={siteData}>
            <ConsentModal requestedPage='upload' reactCallback={consentGiven} reactPreflight={initSiteData} />
     <HomePage />
        </websiteContext.Provider>
      </MantineProvider>
  );
}

export default App;
