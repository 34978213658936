import { createContext } from 'react';
import User from "../../User";

export const websiteContext = createContext({consent: null, user: null, siteData: null});

export async function getUser() {
    var myHeaders = new Headers();
    if (process.env.NODE_ENV == 'development') {
        myHeaders.append("CF-Access-Client-Id", process.env.REACT_APP_CF_ID);
        myHeaders.append("CF-Access-Client-Secret", process.env.REACT_APP_CF_SECRET);
    }
    myHeaders.append("Authorization", localStorage.getItem("token"));
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        credentials: 'include'
    };

    const response = await fetch(process.env.REACT_APP_YSNDIT_ENDPOINT + "user/info", requestOptions);
    const data = await response.json();
    /*
            Set a stable browser id cookie (if third party cookies is disabled).
            */
    let browserId = window.localStorage.getItem('browser_id');
    if(browserId == undefined || browserId == null || browserId == "") {
        window.localStorage.setItem('browser_id', data["browser_id"]);
        browserId = data["browser_id"];
    }
    return {user: new User(data["user_email"], data["user_name"], data["user_id"], data["authenticated"], browserId, data["default_download_domain"], data["all_download_domains"], data["adFreeDownloads"], data["avatar"], data["newsletter"], data["mobile"]), siteData: initSiteData(data)};
}

export function initSiteData(data=null) {
    const title = document.getElementById("title");
    title.textContent = data.siteName;
    const description = document.getElementById("description");
    description.content = data.description;
    const favicon = document.getElementById("favicon");
    favicon.href = process.env.REACT_APP_YSNDIT_ENDPOINT + data.favicon.substring(1);
    const largerIcon = document.getElementById("larger_icon");
    largerIcon.href = process.env.REACT_APP_YSNDIT_ENDPOINT + data.logo.substring(1);

    return {siteName: data.siteName, description: data.description, favicon: process.env.REACT_APP_YSNDIT_ENDPOINT + data.favicon.substring(1), logo: process.env.REACT_APP_YSNDIT_ENDPOINT + data.logo.substring(1), privacyUrl: data.privacyUrl, termsUrl: data.termsUrl, imprintUrl: data.imprintUrl, helpUrl: data.helpUrl, premium_purchasable: data.premium_purchasable}
}